<template>
  <div class="wrapper">
    <!-- 服务记录添加/修改 -->
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="服务对象" prop="serviceOldPersonId">
            <el-select
                v-model="form.serviceOldPersonId"
                filterable
                placeholder="请选择服务对象"
                style="width:100%;"
                :disabled="isDetail"
                @change="serviceOldPersonChange"
            >
              <el-option v-for="item in personList" :key="item.id" :label="item.personName" :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-show="!$route.params.serviceOrgId" :span="8">
          <el-form-item label="服务公司" prop="serviceOrgId">
            <el-select v-model="form.serviceOrgId" placeholder="请选择服务公司" style="width:100%;" :disabled="isDetail">
              <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务项目" prop="serviceItem">
            <el-input
                v-model="form.serviceItem"
                placeholder="请输入服务项目"
                auto-complete="off"
                :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="联系方式" prop="tel">
            <el-input
                v-model="form.tel"
                placeholder="请输入联系方式"
                auto-complete="off"
                :disabled="isDetail"
                @input="(v) => (form.tel = v.replace(/[^\d]/g, ''))"
            />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="服务评分">
            <el-input
                v-model="form.serviceScore"
                placeholder="请输入服务评分"
                auto-complete="off"
                :disabled="isDetail"
            />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="服务分值">
            <el-input
                v-model="form.satisfaction"
                placeholder="请输入服务分值"
                auto-complete="off"
                :disabled="isDetail"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="服务员工" prop="serviceWorkPersonId">
            <el-select
                v-model="form.serviceWorkPersonId"
                placeholder="请选择服务员工"
                filterable
                style="width:100%;"
                :disabled="isDetail"
            >
              <el-option v-for="item in servePersonList" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务来源">
            <el-select v-model="form.source" placeholder="请选择服务来源" style="width:100%;" :disabled="isDetail">
              <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="订单状态" prop="status">
            <el-select v-model="form.status" placeholder="请选择订单状态" style="width:100%;" :disabled="isDetail">
              <el-option
                  v-for="item in orderStatusList"
                  :key="item.dictPidVal"
                  :label="item.dictDesc"
                  :value="item.dictPidVal"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">

          <el-form-item label="服务开始时间" prop="serviceBeginTime">
            <el-date-picker
                v-model="form.serviceBeginTime"
                type="datetime"
                placeholder="选择日期时间"
                style="width:100%;"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="beginTimeChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务结束时间" prop="serviceEndTime">
            <el-date-picker
                v-model="form.serviceEndTime"
                style="width:100%;"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
                @change="endTimeChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务时长">
            <el-input v-model="form.serviceTime" placeholder="请输入服务时长" auto-complete="off" disabled/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="服务区域" prop="tenant">
            <region-form ref="regionForm" v-model="form.tenant" :disabled="true"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="0" prop="address">
            <el-input v-model="form.address" disabled placeholder="请输入详细地址" auto-complete="off"/>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item  label="服务区域" prop="province">
          <el-select v-model="form.province" placeholder="请选择省" style="width:100%;" @change="provinceChange">
              <el-option v-for="item in provinceList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="4">
          <el-form-item label-width="0" prop="city">
            <el-select v-model="form.city" placeholder="请选择市" style="width:100%;" @change="cityChange">
              <el-option v-for="item in cityList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
      </el-col>
      <el-col :span="4">
          <el-form-item label-width="0" prop="area">
            <el-select v-model="form.area" placeholder="请选择区" style="width:100%;" @change="areaChange">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label-width="0">
          <el-select v-model="form.street" placeholder="请选择街道" style="width:100%;" :disabled="isDetail">
            <el-option
              v-for="item in streetList"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label-width="0">
          <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off" :disabled="isDetail"></el-input>
        </el-form-item>
      </el-col>
    </el-row> -->

      <el-row>
        <el-col :span="12">
          <el-form-item label="服务内容">
            <el-input
                v-model="form.serviceContent"
                type="textarea"
                :rows="2"
                placeholder="请输入服务内容"
                :disabled="isDetail"
                auto-complete="off"
            />
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="情况说明">
            <el-input
                v-model="form.serviceContentDesc"
                type="textarea"
                :rows="2"
                placeholder="请输入情况说明"
                :disabled="isDetail"
                auto-complete="off"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="服务前照片">
            <upload-img :limit="6" :upload-lists="beforeImgsList" @uploadChange="onBeforeImg"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="服务后照片">
            <upload-img :limit="6" :upload-lists="afterImgsList" @uploadChange="onAfterImg"/>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
      <el-col>
        <el-form-item  label="地图">

        </el-form-item>

      </el-col>
    </el-row> -->
    </el-form>
    <div class="m-footer">
      <el-button v-if="!isDetail" type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
      <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import {
  mapActions
} from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import RegionForm from '@/components/RegionForm.vue'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg,
    RegionForm
  },
  data() {
    var validTel = (rule, value, callback) => {
      // if (value && !this.$regex.telRegEx(this.form.tel)) {
      //   callback(new Error('联系方式格式不正确'));
      // } else {
      //   callback();
      // }
    }
    return {
      isDetail: false,
      titleName: '',
      form: {
        serviceScore: 100,
        city: '西安市',
        tel: '',
        serviceTime: ''
      },
      cityList: [],
      provinceList: [],
      serveTime: [],
      beforeImgsList: [],
      afterImgsList: [],
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true
      },
      rules: {
        serviceOldPersonId: [
          { required: true, message: '请选择服务对象', trigger: 'blur' }
        ],
        serviceItem: [
          { required: true, message: '请输入服务项目', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' }
          // { validator: validTel, trigger: 'blur' }
        ],
        serviceBeginTime: [
          { required: true, message: '请选择开始时间', trigger: 'blur' }
        ],
        serviceWorkPersonId: [
          { required: true, message: '请选择服务员工', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择订单状态', trigger: 'blur' }
        ],
        serviceContent: [
          { required: true, message: '请输入服务内容', trigger: 'blur' }
        ]
      },
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      sourceList: [
        {
          value: 1,
          label: '服务企业(居家养老)'
        },
        {
          value: 3,
          label: '社区医疗机构(居家养老)'
        },
        {
          value: 4,
          label: '医院'
        },
        {
          value: 5,
          label: '商家'
        },
        {
          value: 6,
          label: '机构养老'
        },
        {
          value: 7,
          label: '志愿者服务'
        },
        {
          value: 11,
          label: '服务站(社区养老)'
        },
        {
          value: 12,
          label: '日照中心(社区养老)'
        },
        {
          value: 13,
          label: '综合养老服务中心(社区养老)'
        },
        {
          value: 14,
          label: '社区医疗机构(社区养老)'
        }, {
          value: 21,
          label: '养老早市'
        }, {
          value: 22,
          label: '老年餐'
        }, {
          value: 23,
          label: '移动餐车'
        }, {
          value: 31,
          label: ' 农村幸院'
        }, {
          value: 51,
          label: ' 适老化改造施工单位'
        }
      ],
      orderStatusList: [],
      areaList: [],
      streetList: [],
      personList: [],
      orgList: [],
      servePersonList: [],
      map: null
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
    this.getProvincesList()
    // this.initMap()
  },
  methods: {
    ...mapActions([
      'downloadFile',
      'addServeRecordsServe',
      'updateServeRecordsServe',
      'getSysDictServe',
      'getProvincesServe',
      'getCitysServe',
      'getAreasServe',
      'getStreetsServe',
      'getHomePersonListServe',
      'detailHomeOldPersonServe',
      'getServeOrgsList1',
      'getAllPageListServe',
      'detailServeRecordsServe'
    ]),
    getProvincesList() {
      this.getProvincesServe().then((res) => {
        if (res.code == 200) {
          this.provinceList = res.data
        }
      })
    },
    provinceChange(val) {
      if (val) {
        this.getCitysList(val)
      }
    },
    // 获取市区
    getCitysList(val) {
      const params = {
        parenName: val
      }
      this.getCitysServe(params).then((res) => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    },
    cityChange(val) {
      if (val) {
        this.getAreaList(val)
      }
    },
    // initMap() {
    //   console.log('initMap()');
    //   this.map = new BMapGL.Map("container"); //创建一个地图实例
    //   console.log(this.map,'this.map');
    //   var point = new BMapGL.Point(116.404, 39.915);  // 创建点坐标
    //   this.map.centerAndZoom(point, 15);
    //   // this.map.centerAndZoom(new BMapGL.Point(116.404, 39.915), 13); //设置坐标点和展示级别
    //   // this.map.setMapStyleV2({
    //   //   styleId: "9e19b3c9f41d83f25b8c14757ec8dfac", //样式ID
    //   // });
    //   this.map.enableScrollWheelZoom(true); // 允许地图可被鼠标滚轮缩放

    //   var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
    //   this.map.addControl(scaleCtrl);

    // //   //创建图标
    // //   let myIcon = new BMapGL.Icon(
    // //     require("@/assets/images/circle.png"),
    // //     new BMapGL.Size(12, 26)
    // //   );
    // //   // 创建Marker标注，使用图标
    // //   // let point = new BMapGL.Point("116.404", "39.915");
    // //   // let marker = new BMapGL.Marker(point, {
    // //   //   icon: myIcon,
    // //   // });
    // //   // 将标注添加到地图
    // //   // this.map.addOverlay(marker);

    // //   // 信息窗口内容----点击图标打开信息窗口
    // //   // let opts = {
    // //   //   width: 60, // 信息窗口宽度
    // //   //   height: 50, // 信息窗口高度
    // //   // };
    // //   // let content = ` <div class="alarmDiv">
    // //   //                   <div class="imgbj">
    // //   //                     <div class="imgtitle">内容</div>
    // //   //                   </div>
    // //   //                   <p>标题</p>
    // //   //                 </div>`;
    // //   // let infoWindow = new BMapGL.InfoWindow(content, opts); // 创建信息窗口对象
    // //   // marker.addEventListener("click", () => {
    // //   //   this.map.openInfoWindow(infoWindow, point); //开启信息窗口
    // //   // });
    // },
    initView() {
      this.getPersonList()
      this.getOrgList()
      this.getServePersonList()
      this.getSysDictList('homeServRecdStatus', 'orderStatusList')
      // this.getAreaList('西安市')
      if (this.routeType == 'add') {
        this.titleName = '添加服务记录'
      } else if (this.routeType == 'xiangqing') {
        this.isDetail = true
        this.titleName = '服务记录详情'
        this.getServeRecordsDetail()
      } else {
        this.titleName = '修改服务记录'
        // 这里调用请求编辑时的详情接口渲染form表单数据
        this.getServeRecordsDetail()
      }
    },
    // 查服务记录详情
    getServeRecordsDetail() {
      const params = {
        id: this.$route.params.id
      }
      this.detailServeRecordsServe(params).then((res) => {
        if (res.code == 200) {
          console.log(res.data)
          // this.form.serviceTime = (Math.floor(this.$tools.timeDifference(this.form.serviceBeginTime,this.form.serviceEndTime)/60) + "小时" + (this.$tools.timeDifference(this.form.serviceBeginTime,this.form.serviceEndTime)%60) + "分" );
          res.data.serviceScore == null ? res.data.serviceScore = 100 : res.data.serviceScore
          res.data.serviceTime = Math.floor(res.data.serviceTime / 60) + '小时' + (res.data.serviceTime % 60) + '分'
          this.form = {
            id: this.$route.params.id,
            ...res.data
          }

          this.form.tenant = res.data.tenant.split(',').slice(2)
          this.$nextTick(() => {
            this.$refs.regionForm.initRegion()
          })

          // if(this.form.serviceBeginTime!=null&&this.form.serviceEndTime!=null){
          //   this.form.serveTime = [this.form.serviceBeginTime, this.form.serviceEndTime]
          // }
          // 如果图片存在，查图片详情
          if (this.form.beforeImgs) {
            this.getFileDetail(this.form.beforeImgs, 1)
          }
          if (this.form.afterImgs) {
            this.getFileDetail(this.form.afterImgs, 2)
          }
          // this.initMap()
          // setTimeout(this.initMap(),10000);
        }
      })
    },
    getFileDetail(ids, type) {
      ids = ids.split(',')
      ids.forEach(item => {
        this.downloadFile(item).then(res => {
          if (type == 1) {
            this.beforeImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          } else {
            this.afterImgsList.push({
              id: item,
              url: window.URL.createObjectURL(res.data)
            })
          }
        })
      })
    },
    onBeforeImg(data) {
      this.form.beforeImgs = data.join(',')
    },
    onAfterImg(data) {
      this.form.afterImgs = data.join(',')
    },
    // 获取服务对象
    getPersonList() {
      const params = {
        current: 1,
        size: 3000,
        param: {}
      }
      this.getHomePersonListServe(params).then(res => {
        // console.log('aaaaaaaaaaaaaaaaa',typeof(res));
        if (res.code == 200) {
          // console.log(res.data.records,'res.data.records');
          this.personList = res.data.records
        }
      })
    },
    serviceOldPersonChange(val) {
      this.getPersonDetail(val)
    },
    // 人员详情
    getPersonDetail(val) {
      const params = {
        id: val
      }
      this.detailHomeOldPersonServe(params).then((res) => {
        if (res.code == 200) {
          this.form.tel = res.data.contract
          this.form.tenant = res.data.tenant.split(',').slice(2)
          this.form.address = res.data.usualAddress
          this.$nextTick(() => {
            this.$refs.regionForm.initRegion()
          })
        }
      })
    },
    // 获取服务企业
    getOrgList() {
      const params = {
        current: 1,
        size: 99,
        param: {}
      }
      this.getServeOrgsList1(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.orgList = res.data
        }
      })
    },
    // 获取服务员工
    getServePersonList() {
      const params = {
        current: 1,
        size: 3000,
        param: {
          'contTel': '',
          'jobTitle': '',
          'name': ''
        }
      }
      this.getAllPageListServe(params).then(res => {
        if (res.code == 200) {
          this.servePersonList = res.data.records
        }
      })
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      const params = {
        typeCode: code
      }
      this.getSysDictServe(params).then(res => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    // 获取区县list
    getAreaList(val) {
      const params = {
        parenName: val
      }
      this.getAreasServe(params).then(res => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetList(val)
      }
    },
    // 获取街道list
    getStreetList(val) {
      const params = {
        parenName: val
      }
      this.getStreetsServe(params).then(res => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    beginTimeChange(data) {
      console.log(data)
      this.form.serviceBeginTime = data

      if (this.form.serviceBeginTime && this.form.serviceEndTime) {
        this.form.serviceTime = (Math.floor(this.$tools.timeDifference(this.form.serviceBeginTime, this.form.serviceEndTime) / 60) + '小时' + (this.$tools.timeDifference(this.form.serviceBeginTime, this.form.serviceEndTime) % 60) + '分')
      }
    },
    endTimeChange(data) {
      this.form.serviceEndTime = data

      if (this.form.serviceBeginTime && this.form.serviceEndTime) {
        this.form.serviceTime = (Math.floor(this.$tools.timeDifference(this.form.serviceBeginTime, this.form.serviceEndTime) / 60) + '小时' + (this.$tools.timeDifference(this.form.serviceBeginTime, this.form.serviceEndTime) % 60) + '分')
      }
    },
    submitEvent(formName) {
      console.log(this.$route)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const time = this.form.serviceTime
          console.log(this.form.serviceTime, isNaN(Number(this.form.serviceTime, 10)))
          if (isNaN(Number(this.form.serviceTime, 10))) {
            console.log('是')
            var arrminutes = time.split('小时')
            var h = arrminutes[0]
            var m = arrminutes[1].split('分')[0]
            this.form.serviceTime = Number(h) * 60 + Number(m)
          }
          // let params =this.form
          const params = {
            id: this.$route.params.id ? this.$route.params.id : null,
            serviceOldPersonId: this.form.serviceOldPersonId,
            serviceOrgId: this.$route.params.serviceOrgId || this.form.serviceOrgId,
            serviceItem: this.form.serviceItem,
            tel: this.form.tel,
            serviceScore: this.form.serviceScore,
            satisfaction: this.form.satisfaction,
            serviceWorkPersonId: this.form.serviceWorkPersonId,
            source: this.form.source,
            status: this.form.status,
            serviceBeginTime: this.form.serviceBeginTime,
            serviceEndTime: this.form.serviceEndTime,
            serviceTime: this.form.serviceTime,
            province: this.form.province,
            city: this.form.city,
            area: this.form.area,
            street: this.form.street,
            address: this.form.address,
            serviceContent: this.form.serviceContent,
            serviceContentDesc: this.form.serviceContentDesc,
            beforeImgs: this.form.beforeImgs,
            afterImgs: this.form.afterImgs
          }
          // console.log(params);
          // // this.form.serviceTime = (Math.floor(this.form.serviceTime/60) + "小时" +(this.form.serviceTime%60) + "分" )

          // console.log(params);
          params.tenant = `1,101,${this.form.tenant.join(',')}`
          this.addServeRecordsServe(params).then(res => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success'
              })
              this.$router.go(-1)
            } else {
              this.$message.error(res.msg)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  height: 100%;
  overflow: height;
  display: flex;
  flex-direction: column;

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .el-form {
    padding: 10px;
    flex: 1;
    overflow: auto;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }

  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;

    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
